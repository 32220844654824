import {config} from "../ApiConstants";
// import axios from "axios";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import {IFinReport, IFinReportPage} from "../model/finReport/IFinReport";
import axiosApi from "../axiosApi/axiosConfig";
import {ICity} from "../model/city/ICity";

export function useCityController(){
    const dispatch = useAppDispatch()

    async function getCities(): Promise<ICity[]>{
        try{
            const response = await axiosApi.get<ICity[]>(config.url.CITY + '/all')
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання міст.', message:(error.response?.data as any).message}))
            return []
        }
    }

    return {
        getCities
    }

}