import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {NavLink as RouterLink, useLocation} from 'react-router-dom';
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import {useAppDispatch} from "../../app/hooks/appHooks";
import {clearAuth} from "../../app/reducers/auth/authSlice";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import {logOut} from "../../app/controllers/authController";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';

import AddCardIcon from '@mui/icons-material/AddCard';
import {navigationItems} from "../../pages/navigation/navigationItems";
import CalculateIcon from '@mui/icons-material/Calculate';
import DifferenceIcon from '@mui/icons-material/Difference';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {CurrencyExchange} from "@mui/icons-material";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import TelegramIcon from '@mui/icons-material/Telegram';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';


interface PropsInterface {
    onSelect(event: React.KeyboardEvent | React.MouseEvent):void
}

export default function MenuList(props: PropsInterface) {
    const { onSelect } = props;
    const dispatch = useAppDispatch()
    const location = useLocation();


    const handleLogout = () => {
        logOut()
        dispatch(clearAuth())
    };

    return (
        <Box sx={{width: 300}} role="presentation" onClick={onSelect} onKeyDown={onSelect}>
            <Box sx={{height:'50px', width:'200px'}}>
                {/*<SVGComponent11 />*/}
            </Box>

            <List >
                {/*<Divider />*/}
                <ListItem key={0} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.dashboard.path}
                                    state={{pathName: navigationItems.dashboard.name}}
                                    selected={location.pathname === navigationItems.dashboard.path} >
                        <ListItemIcon>
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.dashboard.name} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={1} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.cardTopUp.path}
                                    state={{pathName: navigationItems.cardTopUp.name}}
                                    selected={location.pathname === navigationItems.cardTopUp.path} >
                        <ListItemIcon>
                            < AddCardIcon/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.cardTopUp.name} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={2} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.transferFunds.path}
                                    state={{pathName: navigationItems.transferFunds.name}}
                                    selected={location.pathname === navigationItems.transferFunds.path} >
                        <ListItemIcon>
                            < CurrencyExchange/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.transferFunds.name} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={3} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.finReport.path}
                                    state={{pathName: navigationItems.finReport.name}}
                                    selected={location.pathname === navigationItems.finReport.path} >
                        <ListItemIcon>
                            < MonetizationOnIcon/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.finReport.name} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={4} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.compareReport.path}
                                    state={{pathName: navigationItems.compareReport.name}}
                                    selected={location.pathname === navigationItems.compareReport.path} >
                        <ListItemIcon>
                            < DifferenceIcon/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.compareReport.name} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={5} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.ordersReport.path}
                                    state={{pathName: navigationItems.ordersReport.name}}
                                    selected={location.pathname === navigationItems.ordersReport.path} >
                        <ListItemIcon>
                            < ShoppingCartOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.ordersReport.name} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={6} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.fraudReport.path}
                                    state={{pathName: navigationItems.fraudReport.name}}
                                    selected={location.pathname === navigationItems.fraudReport.path} >
                        <ListItemIcon>
                            < CarCrashOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.fraudReport.name} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={7} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.drivers.path}
                                    state={{pathName: navigationItems.drivers.name}}
                                    selected={location.pathname === navigationItems.drivers.path} >
                        <ListItemIcon>
                            < TelegramIcon/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.drivers.name} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={8} disablePadding>
                    <ListItemButton component={RouterLink} to={navigationItems.logout.path}
                                    state={{pathName: navigationItems.logout.name}}
                                    selected={location.pathname === navigationItems.logout.path} >
                        <ListItemIcon>
                            < LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary={navigationItems.logout.name} />
                    </ListItemButton>
                </ListItem>

                {/*<ListItem key={8} onClick={ handleLogout } disablePadding>*/}
                {/*    <ListItemButton  >*/}
                {/*        <ListItemIcon>*/}
                {/*            < LogoutIcon/>*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary={'Вихід'} />*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}
            </List>
        </Box>
    );
}