import React, {useState} from 'react'
import {Box, Tab} from "@mui/material";
import DriversTable from "../components/drivers/DriversTable";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import DriversControl from "../components/drivers/DriversControl";

export default function DriversPage({pageHeight}:{pageHeight: number}) {
    const [tabIndex, setTabIndex] = useState<string>("drivers")
    const tabHeight: number = 48
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue)
    };

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1' }}>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', ml:3, mr:3, height: tabHeight + 'px' }} aria-label={'test'}>
                    <TabList onChange={handleChange} aria-label="Operations history tab" textColor="secondary"
                             indicatorColor="secondary">
                        <Tab label={'Водії'} value="drivers" sx={{color:'primary.main'}} />
                        <Tab label={'Керування'} value="driversControl" sx={{color:'primary.main'}} />
                    </TabList>
                </Box>
                <TabPanel value="drivers" sx={{p:0}}>
                    <DriversTable fullHeight={pageHeight - tabHeight} isAutoHeight={false}/>
                </TabPanel>
                <TabPanel value="driversControl" sx={{p:0}}>
                    <DriversControl/>
                </TabPanel>
            </TabContext>
        </Box>
    );
}
