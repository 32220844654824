import * as React from 'react';
import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import {
    GridColDef,
    GridFilterModel,
    GridLogicOperator,
    GridRenderCellParams, GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import {Link} from "@mui/material";
import {IDriver} from "../../app/model/drivers/IDriver";
import TableEmptyOverlay from "../overlay/TableEmptyOverlay";
import StyledOperationTable from "../reports/StyledOperationTable";
import {useDriversController} from "../../app/controllers/useDriversController";
import {IFilters} from "../../app/model/pageRequestQuery/IFilters";
import {ISort} from "../../app/model/pageRequestQuery/ISort";
import {IFilter} from "../../app/model/pageRequestQuery/IFilter";
import {converterStringTimestampToDate, dateFormatTimeLast} from "../../app/utils/DataUtil";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row[params.field])
}

const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}

const renderDriverLink = (params: GridRenderCellParams<any, string>) => {
    const link = "https://admin.ontaxi.com.ua/drivers/" + params.value + "?tab=3"
    return <Link href={link} underline="hover" target="_blank">
        {params.value}
    </Link>
}

function getNestedValue<T>(obj: any, path: string): T | undefined {
    return path.split('.').reduce((o, p) => o && o[p], obj) as T;
}

function getValue<T>(params: GridValueGetterParams<any, any>): T | undefined {
    const value = getNestedValue<T>(params.row, params.field);
    return value
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

const getCityOptions = (): { value: number, label: string }[] => {
    const options: { value: number, label: string }[] = []
    options.push({value: 39, label: 'Краматорськ'})
    options.push({value: 29, label: "Слов'янськ"})
    options.push({value: 981, label: "Константинівка"})
    return options
}

export default function DriversTable({fullHeight, isAutoHeight}:{fullHeight:number, isAutoHeight:boolean}) {
    const [isLoading, setIsLoading] = useState(false)
    const [drivers, setDrivers] = useState<IDriver[]>([])
    const {getDriversPage} = useDriversController()
    const [paginationModel, setPaginationModel] = React.useState({pageSize: 500, page: 0});
    const [rowCount, setRowCount] = useState(0)
    const [filterModel, setFilterModel] = useState<GridFilterModel>({items:[], logicOperator:GridLogicOperator.And})
    const [sortModel, setSortModel] = useState<GridSortModel>([])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id водія', flex: 1, minWidth: 180, maxWidth: 200, type: 'number', renderCell: renderDriverLink},
        { field: 'isOnline', headerName: 'Онлайн', flex: 1, minWidth: 80, maxWidth: 100, type: 'boolean'},
        { field: 'profile.fullPhone', headerName: 'Телефон', flex: 1, minWidth: 180, maxWidth: 200, type: 'string', valueGetter: getValue<string> },
        { field: 'profile.name', headerName: 'Ім`я', flex: 1, minWidth: 180, maxWidth: 200, type: 'string', valueGetter: getValue<string> },
        { field: 'profile.surname', headerName: 'Фамілія', flex: 1, minWidth: 180, maxWidth: 200, type: 'string', valueGetter: getValue<string> },
        { field: 'account.acceptPercent', headerName: '% прийняття', flex: 1, minWidth: 120, maxWidth: 150, type: 'number', valueGetter: getValue<number> },
        { field: 'lastOrder', headerName: 'Останне замовлення', flex: 1, minWidth: 180, maxWidth: 200, type: 'dateTime', valueGetter: dateTimeConverter, valueFormatter: formatDate },
        { field: 'profile.cityId', headerName: 'Місто', flex: 1, minWidth: 150, maxWidth: 200, type: 'singleSelect', valueGetter: getValue<number>, valueOptions: getCityOptions },
        { field: 'isTelegramBotRegistered', headerName: 'Бот', flex: 1, minWidth: 180, maxWidth: 200, type: 'boolean' },
    ];

    useEffect(() => {
        loadData(filterModel, sortModel, paginationModel.pageSize, paginationModel.page)
    }, [paginationModel, filterModel, sortModel]);

    async function loadData(filterModel:GridFilterModel, sort: GridSortModel, pageSize: number, pageNumber: number){
        setIsLoading(true)

        if (pageSize < 500){
            pageSize = 500
        }

        const filterItems: IFilter[] = filterModel.items.map(value => {
            return {field: value.field, operator: value.operator, value: value.value}
        })
        const filter: IFilters = {filters: filterItems, operator: filterModel.logicOperator}

        let sortItem: ISort | null = null
        if (sort.length > 0){
            sortItem = {sortingField: sort[0].field, order: sort[0].sort}
        }

        const page = await getDriversPage({
            page: {page: pageNumber, pageSize: pageSize},
            sort: sortItem,
            filter: filter
        })
        setRowCount(page.totalRows)
        setDrivers(page.rows)
        setIsLoading(false)
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: fullHeight + 'px'}}>
            <StyledOperationTable
                rows={ drivers }
                columns={columns}
                loading={isLoading}
                rowCount={rowCount}
                pagination
                pageSizeOptions={[500, 1000, 2000, 5000]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode="server"
                filterModel={filterModel}
                filterMode="server"
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                sortingMode="server"
                density="compact"
                autoHeight={isAutoHeight}
                hideFooter={false}
                hideFooterPagination={false}
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
            />
        </Box>
    );
}