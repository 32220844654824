import React, {useEffect, useState} from 'react';
import {createHashRouter, createRoutesFromElements, HashRouter, Route, RouterProvider, Routes} from "react-router-dom";
import {navigationItems} from "./pages/navigation/navigationItems";
import ProtectedRoute from "./pages/ProtectRoute";
import ErrorPage from "./pages/ErrorPage";
import Layout from "./pages/Layout";
import FinReportsPage from "./pages/FinReportPage";
import {LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LicenseInfo } from '@mui/x-license-pro';
import Dashboard from "./pages/Dashboard";
import CompareReportPage from "./pages/CompareReportPage";
import CardsDepositPage from "./pages/CardsDepositPage";
import TransferFundsReportPage from "./pages/TransferFundsReportPage";
import OrdersReportPage from "./pages/OrdersReportPage";
import DriversTable from "./components/drivers/DriversTable";
import FraudReportPage from "./pages/FraudReportPage";
import DriversPage from "./pages/DriversPage";
import {Logout} from "@mui/icons-material";
import LogOutPage from "./pages/LogOutPage";

LicenseInfo.setLicenseKey('1f0a5c2e357b997a6c995491058ee508T1JERVI6Rnluc3lzdGVtLEVYUElSWT0xNzU0ODU4NzUzNTUwLEtWPTIsUz1wcmVtaXVtLExNPWFubnVhbCxFPTE3NTQ4NTg3NTM1NTA=');




function App() {
    const appBarHeight:number = 45
    const [pageHeight, setPageHeight] = useState(document.documentElement.clientHeight - appBarHeight);

    useEffect(() => {
        const handleResize = () => {
            const newPageHeight = document.documentElement.clientHeight - appBarHeight
            setPageHeight(newPageHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const router = createHashRouter(
        createRoutesFromElements(
            <Route path={navigationItems.dashboard.path} element={ <Layout appBarHeight={appBarHeight}/> } errorElement={<ErrorPage/>}>
                <Route index element={<Dashboard />} />
                <Route path={navigationItems.logout.path} element={
                    <LogOutPage/>} />
                <Route path={navigationItems.cardTopUp.path} element={
                    <ProtectedRoute roles={['ROOT', 'ADMIN', 'MANAGER']}>
                        <CardsDepositPage pageHeight={pageHeight} />
                    </ProtectedRoute> } />
                <Route path={navigationItems.transferFunds.path} element={
                    <ProtectedRoute roles={['ROOT', 'ADMIN', 'MANAGER']}>
                        <TransferFundsReportPage pageHeight={pageHeight} />
                    </ProtectedRoute> } />
                <Route path={navigationItems.finReport.path} element={
                    <ProtectedRoute roles={['ROOT', 'ADMIN', 'MANAGER']}>
                        <FinReportsPage pageHeight={pageHeight} />
                    </ProtectedRoute> } />
                <Route path={navigationItems.compareReport.path} element={
                    <ProtectedRoute roles={['ROOT', 'ADMIN', 'MANAGER']}>
                        <CompareReportPage pageHeight={pageHeight} />
                    </ProtectedRoute> } />
                <Route path={navigationItems.ordersReport.path} element={
                    <ProtectedRoute roles={['ROOT', 'ADMIN', 'MANAGER']}>
                        <OrdersReportPage pageHeight={pageHeight} />
                    </ProtectedRoute> } />
                <Route path={navigationItems.fraudReport.path} element={
                    <ProtectedRoute roles={['ROOT', 'ADMIN', 'MANAGER']}>
                        <FraudReportPage pageHeight={pageHeight} />
                    </ProtectedRoute> } />
                <Route path={navigationItems.drivers.path} element={
                    <ProtectedRoute roles={['ROOT', 'ADMIN', 'MANAGER']}>
                        <DriversPage pageHeight={pageHeight} />
                    </ProtectedRoute> } />
            </Route>
        )
    )


  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
      </LocalizationProvider>
  );
}

export default App;
