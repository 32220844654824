export const navigationItems = {
    dashboard: { name: "dashboard", path:"/"},
    logout: { name: "Вихід", path:"/logout"},
    cardTopUp: { name: "Вивід коштів водіям", path:"/cardTopUp"},
    transferFunds: { name: "Звіт переказу коштів між водіями", path:"/transferFunds"},
    finReport: { name: "Фінансовий звіт", path:"/finReport"},
    compareReport: { name: "Порівняння показників", path:"/compareReport"},
    ordersReport: { name: "Замовлення", path:"/ordersReport"},
    fraudReport: { name: "Шахрайство", path:"/fraudReport"},
    drivers: { name: "Водії", path:"/drivers"},

}